 .container {
  padding: 40px 0;
}

 .container .col-xs-12 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

 .container .col-xs-12:last-child {
  align-items: flex-start;
}

 .title {
  composes: appSubtitle from global;
  width: 100%;
}

 .image {
  margin: 0 auto;
}

 .text {
  font-size: 18px;
}
